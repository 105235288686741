import JudithPortrait from "@public/subdomain/about-us/portrait/judith-portrait.png";
import RachelPortrait from "@public/subdomain/about-us/portrait/rachel-portrait.png";
import KeithPortrait from "@public/subdomain/about-us/portrait/keith-portrait.png";
import RuxPortrait from "@public/subdomain/about-us/portrait/rux-portrait.png";
import IzzahPortrait from "@public/subdomain/about-us/portrait/izzah-portrait.png";
import SiongPortrait from "@public/subdomain/about-us/portrait/siong-portrait.png";
import HanisPortrait from "@public/subdomain/about-us/portrait/hanis-portrait.png";
import FildzahPortrait from "@public/subdomain/about-us/portrait/fildzah-portrait.png";
import KevinPortrait from "@public/subdomain/about-us/portrait/kevin-portrait.png";
import TunPortrait from "@public/subdomain/about-us/portrait/tun-portrait.png";
import HauiiPortrait from "@public/subdomain/about-us/portrait/hauii-portrait.png";
import KhaileePortrait from "@public/subdomain/about-us/portrait/khailee-portrait.png";
import SamPortrait from "@public/subdomain/about-us/portrait/sam-portrait.png";
import YuhangPortrait from "@public/subdomain/about-us/portrait/yuhang-portrait.png";
import JustinPortrait from "@public/subdomain/about-us/portrait/justin-portrait.jpg";
import { Trans } from "@lingui/macro";
import { ReactElement } from "react";
import { StaticImageData } from "next/image";

interface PortraitDetails {
  name: string;
  title: ReactElement;
  quote: ReactElement;
  src: StaticImageData;
}

interface CarouselDetails {
  src: string;
  alt: string;
}

export const fellowPortraits: PortraitDetails[] = [
  {
    name: "Judith Yeoh",
    title: <Trans>CEO</Trans>,
    quote: (
      <Trans>
        "It's an honour to work with a team that cares about people, purpose,
        and impact. We want to create a better journey of discovery for all
        Malaysians and for publishers to show their best work to a captured
        audience."
      </Trans>
    ),
    src: JudithPortrait,
  },
  {
    name: "Izzah Hazim",
    title: <Trans>News Curator</Trans>,
    quote: (
      <Trans>
        “My favourite thing about working with Lumi is curating good news! I get
        to immerse myself in the blackhole of news and spread the joy I find
        through good news.”
      </Trans>
    ),
    src: IzzahPortrait,
  },
  // {
  //   name: "Tang Ruxyn",
  //   title: <Trans>Product & Content Manager</Trans>,
  //   quote: (
  //     <Trans>
  //       “Turn to your neighbour and speak your mind. That's how easy it can be
  //       sometimes to turn ideas into reality here. A frightening idea at first,
  //       but there's this satisfaction knowing that the community cares enough to
  //       drop us comments and feedback on Discord.”
  //     </Trans>
  //   ),
  //   src: RuxPortrait,
  // },
  {
    name: "Samantha Yeoh",
    title: <Trans>Content Specialist</Trans>,
    quote: (
      <Trans>
        "Just like the name 'Lumi' - it's my mission to illuminate our users and
        followers through the power of content."
      </Trans>
    ),
    src: SamPortrait,
  },
  {
    name: "Rachel Anyi",
    title: <Trans>Design Lead</Trans>,
    quote: (
      <Trans>
        “The opportunity to change the way people read the news and perceive the
        world with clever design is a great honour and responsibility to have.”
      </Trans>
    ),
    src: RachelPortrait,
  },
  {
    name: "Hanis Saifullah",
    title: <Trans>Creative Marketing Designer</Trans>,
    quote: (
      <Trans>
        “If the plan doesn't work, change the plan never the goals.”
      </Trans>
    ),
    src: HanisPortrait,
  },
  {
    name: "Kevin Sia",
    title: <Trans>Senior Full-Stack Developer</Trans>,
    quote: (
      <Trans>
        “I want to use my technical skills to directly impact the lives of
        millions in Malaysia by creating easier and faster access to credible
        news. “
      </Trans>
    ),
    src: KevinPortrait,
  },
  // {
  //   name: "Joe Lim",
  //   title: <Trans>Full-Stack Developer</Trans>,
  //   quote: (
  //     <Trans>
  //       “I'm one of the latest additions to the Lumi team, but I feel like we've
  //       known each other for a very long time. Such a friendly and cosy working
  //       environment!”
  //     </Trans>
  //   ),
  //   src: JoePortrait,
  // },
  // {
  //   name: "Fildzah Zulkifli",
  //   title: <Trans>Growth Marketing Manager</Trans>,
  //   quote: (
  //     <Trans>
  //       “Few things can motivate me further than working with a talented,
  //       purpose-driven team. We leave our egos at the door every morning, and
  //       just do great work.”
  //     </Trans>
  //   ),
  //   src: FildzahPortrait,
  // },
  {
    name: "Keith Chan",
    title: <Trans>App Developer</Trans>,
    quote: (
      <Trans>
        “It’s great to be part of something like Lumi; it has the potential to
        really change the way people consume media, and here I am, writing the
        code behind it.”
      </Trans>
    ),
    src: KeithPortrait,
  },
  {
    name: "Ang Yu Hang",
    title: <Trans>App Developer</Trans>,
    quote: <Trans>“Own your ambition, don't let it own you.”</Trans>,
    src: YuhangPortrait,
  },
  // {
  //   name: "Tun Amsyar Haziq",
  //   title: <Trans>Senior Backend Developer</Trans>,
  //   quote: (
  //     <Trans>
  //       &#x201c;In three words I can sum up everything I&#x2019;ve learned about
  //       life: it goes on.&#x201d; - Robert Frost
  //     </Trans>
  //   ),
  //   src: TunPortrait,
  // },
  {
    name: "Lee Hauii",
    title: <Trans>Data Analyst</Trans>,
    quote: (
      <Trans>
        &#x201c;It&apos;s super fun to provide data solutions to the team and to
        clients. I make bricks from clay!&#x201d;
      </Trans>
    ),
    src: HauiiPortrait,
  },
  {
    name: "Justin Goh",
    title: <Trans>Growth Marketing Strategist</Trans>,
    quote: (
      <Trans>
        &#x201c;A setback is just a setup for a comeback. The road to success is
        never a straight line!&#x201d;
      </Trans>
    ),
    src: JustinPortrait,
  },
  {
    name: "Siong Ong",
    title: <Trans>Tech Advisor</Trans>,
    quote: (
      <Trans>
        “We don’t want it to be a run-off-the-mill news aggregator. There’s so
        many ways the news can be more than what it is now, and we want to
        innovate and push those boundaries.”
      </Trans>
    ),
    src: SiongPortrait,
  },
  {
    name: "Khailee Ng",
    title: <Trans>Tech Advisor</Trans>,
    quote: (
      <Trans>
        “My focus on discovering economic opportunities led me to back this
        exciting new venture! I can’t wait to see where this goes.”
      </Trans>
    ),
    src: KhaileePortrait,
  },
];

export const firstCarouselItems: CarouselDetails[] = [
  {
    src: "/subdomain/about-us/carousel/1-1.png",
    alt: "Image 1-1",
  },
  {
    src: "/subdomain/about-us/carousel/1-2.png",
    alt: "Image 1-2",
  },
  {
    src: "/subdomain/about-us/carousel/1-3.png",
    alt: "Image 1-3",
  },
  {
    src: "/subdomain/about-us/carousel/1-4.png",
    alt: "Image 1-4",
  },
  {
    src: "/subdomain/about-us/carousel/1-5.png",
    alt: "Image 1-5",
  },
  {
    src: "/subdomain/about-us/carousel/1-6.png",
    alt: "Image 1-6",
  },
  {
    src: "/subdomain/about-us/carousel/1-7.png",
    alt: "Image 1-7",
  },
  {
    src: "/subdomain/about-us/carousel/1-8.png",
    alt: "Image 1-8",
  },
  {
    src: "/subdomain/about-us/carousel/1-9.png",
    alt: "Image 1-9",
  },
  {
    src: "/subdomain/about-us/carousel/1-10.png",
    alt: "Image 1-10",
  },
  {
    src: "/subdomain/about-us/carousel/1-11.png",
    alt: "Image 1-11",
  },
];

export const secondCarouselItems: CarouselDetails[] = [
  {
    src: "/subdomain/about-us/carousel/2-1.png",
    alt: "Image 2-1",
  },
  {
    src: "/subdomain/about-us/carousel/2-2.png",
    alt: "Image 2-2",
  },
  {
    src: "/subdomain/about-us/carousel/2-3.png",
    alt: "Image 2-3",
  },
  {
    src: "/subdomain/about-us/carousel/2-4.png",
    alt: "Image 2-4",
  },
  {
    src: "/subdomain/about-us/carousel/2-5.png",
    alt: "Image 2-5",
  },
  {
    src: "/subdomain/about-us/carousel/2-6.png",
    alt: "Image 2-6",
  },
  {
    src: "/subdomain/about-us/carousel/2-7.png",
    alt: "Image 2-7",
  },
  {
    src: "/subdomain/about-us/carousel/2-8.png",
    alt: "Image 2-8",
  },
  {
    src: "/subdomain/about-us/carousel/2-9.png",
    alt: "Image 2-9",
  },
  {
    src: "/subdomain/about-us/carousel/2-10.png",
    alt: "Image 2-10",
  },
  {
    src: "/subdomain/about-us/carousel/2-11.png",
    alt: "Image 2-11",
  },
  {
    src: "/subdomain/about-us/carousel/2-12.png",
    alt: "Image 2-12",
  },
];
